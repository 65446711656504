@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap");

@layer base {
  * {
    font-family: "Inter", sans-serif;
  }

  .overlay::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    transition: all 0.4s ease-in-out;
    background-color: rgba(0, 0, 0, 0.751);
  }

  html,
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    /* min-height: 120vh; */
  }

  .video-login::-webkit-media-controls,
  .video-login::-webkit-media-controls-play-button,
  .video-login::-webkit-media-controls-start-playback-button {
    display: none !important;
  }

  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  .custom-shadow-s {
    box-shadow: rgba(0, 0, 0, 0.025) 0px 4px 4px;
  }

  .custom-border {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  }

  .rbc-calendar {
    min-height: 580px;
  }

  .bg-all {
    background-color: #7c88c3;
  }

  .bg-toDo {
    background-color: #f97316;
  }

  .bg-doing {
    background-color: #478bf2;
  }

  .bg-finished {
    background-color: #51b069;
  }

  .canvas-container canvas {
    border-width: 1px;
  }

  .common-for-team:last-child .bb-separate {
    display: none;
  }

  .tr-element:last-child {
    border: none;
  }

  .tr-element:last-child .picture-name-mail {
    margin-bottom: 0;
  }

  /* Exemple de transitions CSS pour l'effet de glissement */
  .slide-in {
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
  }

  .slide-out {
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .show-window {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s, visibility 0.3.5s;
  }

  .hide-window {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.35s;
  }

  .title-trunc {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* Définissez le nombre maximum de lignes souhaité */
    -webkit-line-clamp: 2;
    /* Définissez la hauteur maximale souhaitée pour deux lignes */
  }

  #progress-line-fill {
    transition: left 0.3s ease-in-out, width 0.3s ease-in-out;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .loader-spinner {
    animation: rotate 1.2s linear infinite;
  }

  .popup {
    position: fixed;
    top: -200px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: top 0.3s ease-in-out;
  }

  .visible {
    top: 20px; /* Apparaît en haut de l'écran */
  }

  .unvisible {
    top: -200px;
  }

  .next-div {
    align-items: start;
  }

  .custom-shadow {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }

  @media (min-width: 640px) {
    .section-name {
      font-size: 20px;
    }

    .for-team,
    .for-settings,
    .basic-flc,
    .for-settings,
    .for-business {
      display: flex;
    }

    .h-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: auto;
    }

    .h-buttons button {
      width: 47.5%;
    }
  }

  @media (max-width: 639px) {
    .dash-box,
    .for-settings,
    .basic-flc,
    .for-settings,
    .for-business {
      flex-direction: column;
    }

    .cmmon-for-component-dash {
      width: 100%;
    }
  }

  @media (max-width: 639px) {
    .buttons {
      flex-direction: column;
    }

    .for-team {
      flex-direction: column;
    }

    .for--project-head-infos {
      flex-direction: column;
    }
  }

  @media (min-width: 768px) {
    .next-div {
      align-items: start;
    }

    .section-name {
      font-size: 37px;
    }

    .login-section-components {
      width: 80%;
      min-width: 450px;
    }
  }
}
