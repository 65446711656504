@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* body {
    overflow: hidden;
  } */

  .gradient-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 0, 0, 1),
      rgba(255, 0, 0, 0)
    );
    animation: gradient-animation 1s linear infinite;
  }

  @keyframes gradient-animation {
    0% {
      background: linear-gradient(to right, #282a36, #282a36c9);
    }
    100% {
      background: linear-gradient(to right, #282a36, #282a36c9);
    }
  }

  .sliding-div {
    width: 100px;
    height: 100px;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }

  .next-div {
    width: 100px;
    height: 100px;
    transition: transform 0.4s ease-in-out, opacity 0.2s ease-in-out,
      visibility 0.2s, absolute 0.8s ease-in-out;
  }

  .custom-visible {
    display: none;
    transform: translateX(-100%);
    opacity: 0;
    position: absolute;
    transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out,
      visibility 0.2s;
  }

  .custom-visible-reverse {
    display: none;
    transform: translateX(100%);
    opacity: 0;
    position: absolute;
    transition: transform 0.4s ease-in-out, opacity 0.1s ease-in-out,
      visibility 0.2s, absolute 0.8s ease-in-out;
  }

  .revive {
    display: flex;
    transform: translateX(0);
    opacity: 1;
    position: relative;
    visibility: visible;
  }
}
